#number-input {
  border-radius: 0;
}

.zoom {
  transition: transform 0.3s; /* Animation */
}

.zoom:hover {
  transform: scale(1.1);
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  max-width: 1256px;
  margin: auto;
  margin-top: 6em;
}
#root {
  min-height: 100%;
  height: 100%;
  margin: 0 auto -4em;
}
